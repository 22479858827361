.dependency {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000cc;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dependency__option--image {
  text-align: center;
  margin-bottom: 10px;
}

.dependency__option--image img {
  width: 100%;
}

.dependency__wrapper {
  background: #fff;
  padding: 25px;
  width: 800px;
  min-height: 60vh;
}

.dependency__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.dependecy__title {
  font-size: 22px;
}

.dependecy__close {
  cursor: pointer;
}

.dependency__content {
  padding-top: 15px;
  padding-bottom: 15px;
}

.dependency__dependencies {
  display: grid;
  justify-content: space-between;
  padding-top: 25px;
  grid-template-columns: repeat(3, 1fr);
}

.dependency__option {
  flex: 1;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dependency__dependant {
  border-bottom: 1px solid #ccc;
}

.dependency__dependant img {
  width: 100px;
}

.dependency__dependant .dependency__option {
  border: none;
  flex-direction: row;
  justify-content: center;
}

.dependency__info {
  text-align: center;
  margin-bottom: 10px;
}

.dependency__option--price {
  text-align: right;
}
