@import url(https://fonts.googleapis.com/css2?family=Michroma&family=Montserrat:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Orbitron:wght@500&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css);
/*!
 * Bootstrap Grid v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 0.75rem;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: 0.75rem;
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/*# sourceMappingURL=bootstrap-grid.css.map */
@media (min-width: 1200px) {
  .navigation {
    max-height: 550px;
    max-width: 308px;
    top: unset;
    width: unset;
  }
}

.navigation {
  background: #fff;
  margin-left: 40px;
  max-height: 0;
  padding: 0 10px;
  margin-top: 90px;
  transition: max-height 0.2s ease;
  width: calc(100% - 27px);
  z-index: 10;
  list-style: none;
}

.tds-list,
.tds-text--body,
.tds-text--caption-header,
.tds-text--h1,
.tds-text--h2,
.tds-text--h3,
.tds-text--h4,
.tds-text--h5,
.tds-text--h6 {
  line-height: 20px;
}

.tds-list > :is(li, dd):not(:last-of-type) {
  -webkit-margin-after: 8px;
  margin-block-end: 8px;
}

.navigation-link {
  margin: 5px 0 8px;
  padding: 5px 0;
}

.tds-list > :is(li, dd) {
  -webkit-padding-end: 0;
  -webkit-padding-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  position: relative;
}

[dir="ltr"] .navigation-link a {
  padding-left: 46px;
}

.navigation-link.disabled a {
  color: #aaa;
}

.navigation-link a {
  box-shadow: none;
  color: var(--tds-color--grey30);
  display: block;
  font-family: var(--tds-font-combined);
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  transition: color 0.2s ease;
}

a {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.navigation-link.selected .scrim,
.navigation-title .scrim {
  background: var(--tds-color--grey65);
  border-radius: 50%;
}

[dir="ltr"] .navigation-link .scrim,
[dir="ltr"] .navigation-title .scrim {
  margin-left: -46px;
  margin-right: 12px;
}

.navigation-link .scrim,
.navigation-title .scrim {
  display: inline-flex;
  align-items: center;
  height: 34px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  vertical-align: bottom;
  width: 34px;
}

.navigation-link.selected svg,
.navigation-link:hover svg {
  opacity: 1;
}

.navigation-link svg {
  margin-bottom: 1px;
  opacity: 0.72;
  transition: opacity 0.2s ease;
  height: 20px;
}

.font-alt {
  font-family: "Michroma", sans-serif;
}

.account {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.wrapper {
  flex: 1 1;
  display: flex;
  overflow: auto;
  background: #f5f5f5;
}

.wrapper main {
  flex: 1 1;
  margin-top: 25px;
}

.header .logo {
  padding: 15px;
}

.header .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
}

.account-footer {
  padding: 15px;
  text-align: center;
  background-color: #262626;
  color: #bdbdbd;
  font-size: 8px;
  font-family: "Michroma", sans-serif;
}

.website {
  margin-right: 25px;
}

.website a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  padding: 6px;
  font-size: 10px;
  font-family: "Michroma", sans-serif;
}

.website a img {
  margin-right: 8px;
}

.notification {
  margin-right: 25px;
}

.notification a {
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile {
}

.profile a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  padding: 6px;
  padding-right: 40px;
  font-size: 10px;
  font-family: "Michroma", sans-serif;
  background-repeat: no-repeat;
  background-position: right center;
  background-position-x: 115px;
}

.profile a img {
  margin-right: 8px;
  width: 28px;
}

.dashboard-header {
  font-size: 26px;
  margin-bottom: 50px;
  font-family: "Michroma";
}

.dashboard-card {
  position: relative;
  height: 225px;
  margin-bottom: 20px;
  border-radius: 16px;
  overflow: hidden;
}

.dashboard-card .dashboard-link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #00000066;
  font-size: 18px;
  font-family: "Michroma";
}

.account .login-form {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 20px;
}

.account .login-title-sm {
  font-size: 10px;
}

.account .login-title-md {
  font-size: 16px;
  margin-bottom: 25px;
}

.account .login-line {
  text-align: center;
}

.account .login-line input {
  width: 100%;
  border: 1px solid #262626;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.account .login-line select {
  width: 100%;
  padding: 10px 5px;
  border-radius: 8px;
}

.account .button-dark {
  font-family: "Michroma", sans-serif;
  background: #262626;
  border-radius: 8px;
  font-size: 10px;
  color: #fff !important;
  padding: 12px 50px;
  box-shadow: none;
  -webkit-appearance: button;
  outline: none;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
}

.account .button-link {
  -webkit-appearance: none;
          appearance: none;
  background: none;
  border: none;
  color: #6495ed;
  text-decoration: underline;
  cursor: pointer;
}

.account .button-dark.narrow {
  padding: 10px;
}

.account .button-white {
  font-size: 10px;
  color: #262626;
  text-decoration: none;
  border: 1px solid #262626;
  border-radius: 8px;
  padding: 10px 15px;
  box-sizing: border-box;
  margin-right: 15px;
  transition-duration: 0.3s;
}

.account .button-white:hover {
  background: #262626;
  color: #fcd801;
}

.account .login-form a {
  font-family: "Michroma", sans-serif;
  color: #262626;
  text-decoration: none !important;
  font-size: 10px;
}

.bookmarks {
  padding: 15px 0;
  background: #f5f5f5;
}

.bookmarks .link {
  text-decoration: none !important;
  font-size: 10px;
  font-family: "Michroma", sans-serif;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #262626;
  height: 100%;
  transition-duration: 0.3s;
}

.bookmarks .link:hover {
  background: #262626;
  color: #fcd801;
}

.dashboard .row,
.dashboard .col,
.dashboard .card {
  height: 100%;
}

.dashboard .card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.box {
  margin: 12px 0;
  flex: 1 1;
  background-size: cover;
  border-radius: 15px;
  overflow: hidden;
  text-decoration: none;
  font-size: 16px;

  transition-duration: 0.3s;
}

.box.hover {
  color: #fff;
}

.box.hover:hover {
  color: #fcd801;
}

.box .title {
  font-family: "Michroma", sans-serif;
  margin-left: 15px;
  margin-top: 15px;
}

.box .welcome {
  display: inline;
  font-size: 20px;
  border-bottom: 1px solid #fcd800;
  color: #262626;
  font-weight: bold;
}

.box .yacht-logo {
  margin-top: 25px;
}

.box .conf-id {
  font-size: 12px;
  color: #262626;
  text-decoration: none;
}

.box .buttons {
  margin-top: 20px;
}

.sheet {
  border-radius: 15px;
  padding: 20px;
  background: #fff;
  margin-bottom: 25px;
}

.nav {
  list-style: none;
  padding: 0;
}

.nav > li {
  margin-bottom: 20px;
}

.nav > li > .parent {
  font-size: 12px;
  color: #b1b0b0;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav > li.active .parent {
  color: #262626;
}

.nav > li > .icon {
  margin-right: 10px;
}

.nav .text {
  flex: 1 1;
  padding-left: 10px;
}

.nav-child ul {
  list-style: none;
  margin-top: 10px;
}

.nav-child ul > li > a {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #262626;
  text-decoration: none;
}

.sidenav {
  height: 100%;
  overflow: auto;
}

.title-sub {
  font-size: 10px;
}

.form-wrapper,
.list-wrapper {
  margin-top: 15px;
}

.account .form .row {
  margin-top: 15px;
}

.account .form-item label {
  display: block;
  font-size: 10px;
}

.account .form-item input[type="text"],
.account .form-item input[type="date"],
.account .form-item input[type="password"],
.account .form-item textarea {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #262626;
  padding: 10px 15px;
  outline: none;
  margin-top: 5px;
}

.account .form-item select {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #262626;
  padding: 10px 15px;
  outline: none;
  margin-top: 5px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-head {
  font-size: 10px;
}

.table-body {
  color: #878787;
  font-size: 10px;
}

.table-row {
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #878787;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.status-accepted {
  color: #00ef38;
}

.status-rejected {
  color: #ff0000;
}

.sub-active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  margin-right: 7px;
}

.spec {
  margin-bottom: 25px;
  border-bottom: 1px solid #7f7f7f;
}

.spec-title {
  font-size: 10px;
  color: #7f7f7f;
}

.spec-text {
  font-size: 14px;
}

.warranty-items {
}

.warranty-item {
  display: flex;
  padding: 10px 15px;
  border: 1px solid #878787;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
}

.warranty-item:hover {
  background-color: #ebebeb;
}

.warranty-item .text {
  font-size: 11px;
  color: #878787;
}

.warranty-item .icon {
}

.row-title {
  cursor: pointer;
}

.table-row .row-content {
  height: 0;
  color: #2c3e50;
  background: white;
  transition: all 1s;
  display: none;
}

.table-row.active .row-content {
  height: auto;
  display: block;
}

.row-content-inner {
  padding: 10px 0;
}

.table-row .row {
  position: relative;
}

.table-row .row::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  position: absolute;
  right: 0;
}

.table-row.no-content .row::after {
  content: "";
}

.table-row.active .row::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
          user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.dependants {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000cc;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dependants__wrapper {
  background: #fff;
  padding: 25px;
  width: 800px;
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.dependants__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.dependants__title {
  font-size: 22px;
}

.dependants__close {
  cursor: pointer;
}

.dependants__content {
  padding-top: 15px;
  padding-bottom: 15px;
  flex: 1 1;
  height: 100%;
  overflow-y: auto;
}

.dependants__dependencies {
  display: flex;
  justify-content: space-around;
  padding-top: 25px;
  flex-direction: column;
}

.dependants__option {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dependants__option img {
  width: 100px;
}

.dependants__dependant {
  border-bottom: 1px solid #ccc;
}

.dependants__dependant img {
  width: 100px;
}

.dependants__dependant .dependants__option {
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dependants__info {
  text-align: center;
  margin-bottom: 10px;
  color: indianred;
  font-weight: bold;
}

.dependants__option--title {
  font-size: 12px;
}

.dependants__option--price {
  text-align: right;
}

.dependants__footer {
  text-align: right;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.dependants__footer .modelSelector__cleanbutton {
  display: inline-block;
}

.dependency {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000cc;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dependency__option--image {
  text-align: center;
  margin-bottom: 10px;
}

.dependency__option--image img {
  width: 100%;
}

.dependency__wrapper {
  background: #fff;
  padding: 25px;
  width: 800px;
  min-height: 60vh;
}

.dependency__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.dependecy__title {
  font-size: 22px;
}

.dependecy__close {
  cursor: pointer;
}

.dependency__content {
  padding-top: 15px;
  padding-bottom: 15px;
}

.dependency__dependencies {
  display: grid;
  justify-content: space-between;
  padding-top: 25px;
  grid-template-columns: repeat(3, 1fr);
}

.dependency__option {
  flex: 1 1;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dependency__dependant {
  border-bottom: 1px solid #ccc;
}

.dependency__dependant img {
  width: 100px;
}

.dependency__dependant .dependency__option {
  border: none;
  flex-direction: row;
  justify-content: center;
}

.dependency__info {
  text-align: center;
  margin-bottom: 10px;
}

.dependency__option--price {
  text-align: right;
}

.spec_popup__button {
  cursor: pointer;
  text-decoration: underline;
}

.spec_popup__close {
  cursor: pointer;
  position: absolute;
  right: 10%;
  top: 25px;
}

.spec_popup__modal {
  visibility: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  background: #ffffff;
  top: 100%;
  transition-duration: 0.6s;
  transition-timing-function: ease-out;
  padding: 25px 10%;
}

.opened .spec_popup__modal {
  visibility: visible;
  top: 0;
}

.spec_popup__container {
  margin: 0 auto;
  max-width: 1130px;
  height: 100%;
  display: flex;
  padding-top: 60px;
}

.spec_popup__image {
  flex: 1 1;
}

.spec_popup__image img {
  width: 100%;
}

.spec_popup__content {
  flex: 1 1;
  padding: 0 25px;
  overflow-y: auto;
  padding-bottom: 45px;
}

/* @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700;800&display=swap"); */

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

pre {
  white-space: pre-wrap;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}

.mb-25 {
  margin-bottom: 25px;
}

.card {
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.card .card-title {
  font-size: 18px;
  font-weight: bold;
}

.card-content {
  margin-top: 35px;
}

.account-content {
  flex: 1 1;
  overflow: auto;
}

.account-content-inner {
  flex: 1 1;
  padding: 30px;
}

.account-content table {
  width: 100%;
  font-size: 14px;
  font-family: 'Montserrat';
}

.account-content table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.uc {
  text-transform: uppercase;
}

.orbitron {
  font-family: 'Orbitron', sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  border-bottom: 2px solid #ccc;
}

.logo {
  width: 175px;
  padding: 15px 20px 10px 20px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.order-table {
  font-size: 14px;
  border-collapse: collapse;
}

.order-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.order-table td,
.order-table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.steps {
  display: flex;
  flex: 1 1;
  padding-left: 15px;
}

.step {
  cursor: pointer;
  margin: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 15px 0;
  margin-bottom: 0;
  text-align: center;
}

.step-active {
  border-bottom: 3px solid;
}

.step-visited {
}

.content {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.header-user {
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 0 30px;
  font-size: 13px;
}

.header-user span {
  margin-right: 5px;
}

.header-user-inner a {
  color: #000 !important;
}

.header-user a {
  color: #000 !important;
  display: flex;
  align-items: center;
}

.footer {
  height: 100px;
  border-top: 1px solid #000;
  background: #fff;
  color: #000;
  display: flex;
}

.footer .payment-options {
  padding: 1rem 2rem;
}

.footer .price-summary {
  display: flex;
  align-items: center;
  flex: 1 1;
  border-right: 1px solid #fff;
  padding: 1rem 3rem;
}

.footer .price-summary .price-text {
  font-size: 32px;
  font-weight: 500;
}

.footer .price-summary .estimate-payment a {
  color: #fff !important;
  font-size: 0.8em;
}

.footer .delivery {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 1rem 3rem;
  justify-content: flex-end;
}

.footer .delivery .text {
  text-align: right;
  font-size: 12px;
}

.button {
  box-shadow: none;
  border-radius: 30px;
  text-align: center;
  padding: 0.4rem 5rem;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.red-button {
  background: #f00;
  color: #fff;
}

.white-button {
  background: #fff;
  color: #000;
  font-size: 14px !important;
  padding: 0.3rem 1rem !important;
}

.black-button {
  background: #000;
  color: #fff;
}

.button.wide {
  padding: 0.5rem 3rem;
  font-size: 20px;
  border: none;
  outline: 0;
}

.button.small {
  padding: 0.3rem 2rem;
  font-size: 14px;
}

.model-name {
  font-size: 32px;
  font-weight: 700;
  margin-left: 10px;
}

.step-content-inner {
  display: flex;
  flex: 1 1;
}

/* ImageStep */
.step-image-content {
  display: flex;
  flex: 1 1;
}

.step-image-content .left {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.step-image-content .image {
  display: flex;
  flex: 9 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: flex-end;
}

.engine_info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
}

.engine_info__item {
  padding: 15px;
  width: 120px;
  background: #ffffffaa;
}

.engine_info__item .price-text {
  font-size: 24px;
}

.engine_info__title {
  text-align: center;
  font-size: 12px;
}

.engine_info__value {
  text-align: center;
}

.step-image-content .options-wrapper {
  width: 500px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 20px 0;
}

.option-group-title {
  margin-top: 10px;
  color: #666;
  padding: 1rem 0;
}

.option-group-subtitle {
  margin-left: 15px;
  margin-right: 40px;
  margin-top: 35px;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.option-title {
  font-size: 0.9rem;
}

.option-price {
  font-size: 0.8rem;
  font-weight: bold;
}

.option-description {
  font-size: 10px;
  font-style: italic;
}

.option-radio {
  cursor: pointer;
  align-items: center;
  border: 1px solid #333;
  margin: 10px;
  padding: 10px;
  border-radius: 0 24px;
}

.option-radio .option-title {
}

.option-radio .option-radio-description {
  font-size: 14px;
  margin-top: 5px;
}

.option-radio.option-selected,
.option-radio.option-package {
}

.option-radio-image {
  cursor: pointer;
  display: flex;
}

.option-radio-image.image-top {
  flex-direction: column;
}

.option-checkbox-image.image-top {
  flex-direction: column;
}

.singleImage .options {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.option-group {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.option-checkbox-image {
  cursor: pointer;
  padding: 10px 10px;
  display: flex;
}

.step-grid-content {
  display: flex;
  flex: 1 1;
  overflow: auto;
  flex-direction: column;
}

.option-group.multiImage .option-group-options {
  padding-top: 0rem;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.option-group.multi .option-group-options {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 15px;
}

.option-checkbox {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
}

.option-checkbox .option-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.option-checkbox .option-description {
  flex: 1 1;
}

.option-checkbox .option-price {
  font-size: 1rem;
}

.option-checkbox pre {
  line-height: 1.5;
}

.option-checkbox.option-selected,
.option-checkbox.option-package {
  padding: 8px;
  border: 3px solid #b01813;
}

.option-group.multi,
.option-group.multiImage {
}

.option-checkbox .option-title {
}

.option-checkbox-image .option-inner {
  margin: 15px;
}

.option-checkbox-image .option-image img {
  width: 150px;
}

.image-top .option-image img {
  width: 100%;
}

.option-checkbox-image .option-inner .option-image img {
  padding: 5px;
  width: 100%;
}

.option-checkbox-image.option-selected .option-inner .option-image img,
.option-checkbox-image.option-package .option-inner .option-image img {
  padding: 0;
  border: 5px solid #d3282f;
}

.option-package-name {
  padding: 0;
  color: #d3282f;
  font-size: 12px;
}

.option-package {
  opacity: 0.5;
}

.step-summary-content {
  display: flex;
  flex: 1 1;
}

.upgradeoption-step {
  font-size: 16px;
  font-weight: 500;
}

.upgradeoption-option-group-title {
  font-size: 15px;
  margin-top: 5px;
}

.upgradeoption-option-group {
  font-size: 14px;
  margin-top: 10px;
}

.upgradeoption-option {
  font-size: 12px;
}

.step-summary-content .left {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
}

.step-summary-content .heading {
  text-align: center;
}

.step-summary-content .heading h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.step-summary-content .heading h2 {
  font-size: 24px;
  margin-top: 0;
  font-weight: normal;
}

.step-summary-content .buttons {
  text-align: center;
  margin-bottom: 25px;
}

.step-summary-content .image {
  display: flex;
  flex: 9 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.step-summary-content .options-wrapper {
  width: 500px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding-bottom: 50px;
}

.step-summary-content .options-wrapper h2 {
  font-size: 50px;
  margin-top: 0px !important;
  margin-bottom: -20px;
}

.step-summary-content .options-wrapper h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

.standards {
  display: flex;
}

.standards-left {
  margin-right: 15px;
}

.upgrades {
  display: grid;
  grid-template-columns: auto;
  padding: 1rem;
}

.option {
  position: relative;
}

/* .option-hint {
  position: absolute;
  left: 5px;
  top: 5px;
} */

.upgrades .option {
  padding-bottom: 5px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 13px;
  align-items: center;
  position: relative;
}

.upgrades .option-row {
  display: flex;
  padding-top: 10px;
  padding-bottom: 5px;
  align-items: center;
}

.upgrades .option .text {
  margin-right: 10px;
  font-weight: bold;
  flex: 1 1;
}

.upgrades .option .price {
  display: inline-block;
  color: #f00;
}

.step-payment-content {
  display: flex;
  flex: 1 1;
}

.step-payment-content .left {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 2rem 2rem;
  overflow: auto;
}

.step-payment-content .heading {
  text-align: center;
}

.step-payment-content .heading h1 {
  font-size: 52px;
  margin-bottom: 10px;
}

.step-payment-content .heading h2 {
  font-size: 40px;
  margin-top: 0;
  font-weight: normal;
}

.step-payment-content .buttons {
  text-align: center;
  margin-bottom: 25px;
}

.step-payment-content .image {
  display: flex;
  flex: 9 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.step-payment-content .options-wrapper {
  width: 500px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 1rem;
  padding-bottom: 50px;
}

.step-payment-content .options-wrapper h2 {
  font-size: 50px;
}

.step-payment-content .options-wrapper h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

.step-payment-content .image {
  width: 100%;
}

.step-payment-content .purchase-wrapper {
  font-size: 20px;
  border-top: 1px solid;
  margin-top: 15px;
  padding-top: 15px;
}

.step-payment-content .purchase-wrapper .duetoday {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
}

.step-payment-content .purchase-footer-text {
  font-size: 13px;
  margin-top: 10px;
}

.step-payment-content .purchase-price {
  display: flex;
  justify-content: space-between;
}

.reservation {
  margin-top: 15px;
  font-size: 26px;
}

.step-summary-content .purchase-wrapper {
  font-size: 20px;
  border-top: 1px solid;
  margin-top: 15px;
  padding: 15px;
}

.step-summary-content .purchase-price {
  display: flex;
  justify-content: space-between;
}

.step-summary-content .purchase-price .purchase-price-amount {
  font-weight: bold;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.form .form-item {
  padding: 1rem;
  margin: 0 15px;
}

.form label {
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
}

.form input {
  padding: 0.8rem;
  border-radius: 4px;
  width: 100%;
  outline: none !important;
  font-size: 16px;
  border: 1px solid #ccc;
}

.form select {
  padding: 0.8rem 1rem;
  border-radius: 4px;
  width: 100%;
  outline: none !important;
  font-size: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.step-payment-content .left h2 {
  font-size: 32px;
  padding-left: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.step-payment-content .agreement-text {
  padding: 1rem;
  line-height: 2;
  font-size: 12px;
}

.step-complete-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.step-complete-content .complete-content {
  flex: 1 1;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  width: 900px;
  margin: 0 auto;
}

.step-complete-content .image {
  display: flex;
  flex: 2 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.error {
  padding: 1rem;
  background: #b01813;
  color: #fff;
}

.step-content-inner {
  display: none;
}

.flex {
  display: flex;
}

.title {
  display: none;
  padding: 1rem 2rem;
  color: #231f20;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid silver;
  align-items: center;
}

.title svg {
  font-size: 24px;
}

.option-group.multi .option-group-options {
  padding: 2rem;
}

/* add */

/* .singleImage .options {
  display: grid;
  grid-template-columns: 1fr 1fr;
} */

.option-radio-image {
  cursor: pointer;
  padding: 10px 10px;
}

/* .option-radio-image img {
  box-shadow: 0 0 10px 6px #ddd;
  border-radius: 12px;
  width: 100%;
} */

/* .option-radio-image.option-package img,
.option-radio-image.option-selected img {
  padding: 0;
  border: 3px solid #d3282f;
  border-radius: 12px;
} */

.option-title {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.hint {
  margin-right: 5px;
}

.option-price {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 0.2rem;
}

.model-name {
  text-align: center;
}

.option-group-title {
  color: #000;
}

.option-image {
}

.option-image img {
  margin-right: 10px;
}

.option-title {
  font-weight: bold;
}

.option-description {
  margin-top: 10px;
}

.option-radio-image .option-title {
  margin: 0;
}

.option-radio-image .option-description {
  margin: 0;
}

.option-radio-image .option-price {
  margin: 0;
}

.option-content {
  background: #eee;
  padding: 10px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.option-price {
  text-align: right;
  margin-top: 15px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.option-selector {
  width: 12px;
  height: 12px;
  padding: 3px;
  border-radius: 100%;
  border: 1px solid;
  display: inline-block;
  content: '';
  margin-left: 10px;
}

.option-selector.selected .tick {
  display: inline-block;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
}

.option-selector.selected .tick {
  background: red;
}

.indicator {
  display: none;
}

.indicator2 {
  margin-right: 10px;
}

.modelSelector__left {
  flex: 1 1;
  overflow: auto;
}

.modelSelector__rightBackdrop {
  background: #000000a0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.modelSelector__right {
  cursor: auto;
  width: 500px;
  border-left: 1px solid #000;
  overflow: auto;
  position: fixed;
  z-index: 9998;
  transition-duration: 0.6s;
  transition-timing-function: ease-in;
  right: -600px;
  top: 0;
  background: #fff;
  height: 100%;
  overflow-y: auto;
}

.open .modelSelector__right {
  right: 0px;
}

.modelSelector__title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
}

.modelSelector__title strong {
  font-size: 30px;
}

.modelSelector__titleSlogan {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.modelSelector__name {
  margin-top: 10px;
  font-size: 24px;
}

.modelSelector__slogan {
  font-size: 14px;
  margin-top: 10px;
}

.modelSelector__content_title {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  border-bottom: 1px solid #000;
  padding-bottom: 2px;
  margin-bottom: 3px;
  padding-right: 8px;
}

.modelSelector__length {
  font-size: 14px;
  margin-top: 10px;
}

.modelSelector__price {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.modelSelector__cta {
  font-size: 12px;
  margin-top: 25px;
}

.modelSelector__items {
  padding: 35px 35px;
  padding-top: 0;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  overflow-y: auto;
}

.open .modelSelector__items {
}

.modelSelector__item {
  padding: 25px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.modelSelector__item .modelSelector__image img {
  padding-bottom: 2px;
}

.modelSelector__item.selected .modelSelector__image img {
  border-bottom: 2px solid #d5d5d5;
  padding: 0;
}

.modelSelector__item.selected .indicator {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
}

.modelSelector__image {
  flex: 2 1;
}

.modelSelector__content {
  flex: 1 1;
  text-align: center;
}

.modelSelector__image img {
  width: 100%;
}

.modelSelector__footer {
  background: #fff;
  border-top: 1px solid #000;
}

.selectedModel__title {
  margin-top: 20px;
  font-size: 28px;
}

.selectedModel__subtitle--top {
  border-left: 40px solid;
  padding-left: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  /* height: 14px; */
}

.selectedModel__subtitle--bottom {
  margin-left: 20px;
  border-left: 20px solid #000;
  padding-left: 15px;
  letter-spacing: 2px;
  margin-top: 10px;
  /* height: 14px; */
  font-size: 14px;
}

.selectedModel__tech {
  margin-top: 25px;
  margin-bottom: 15px;
}

.selectedModel__subtitle {
  margin-bottom: 15px;
}

.selectedModel__standardspecs {
  padding: 20px;
}

.selectedModel__specs {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  padding: 20px;
}

.selectedModel__spec {
  margin-bottom: 15px;
}

.selectedModel__spec--key {
  font-weight: bold;
}

.selectedModel__spec--text {
}

.selectedModel__tabs--item {
  margin-bottom: 10px;
}

.selectedModel__tabs--item.selected .selectedModel__tabs--content {
  display: block;
}

.selectedModel__tabs--title {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 10px;
}

.selectedModel__tabs--title svg {
  margin-right: 10px;
}
.selectedModel__tabs--content {
  display: none;
  border-top: 2px solid;
}

.selectedModel__tabs--content pre {
  line-height: 1.5;
  font-size: 13px;
}

.selectedModel__engine {
  padding: 10px 20px;
}

.selectedModel__engine table tr td:first-child {
  padding-right: 25px;
  font-weight: bold;
}

.modelSelector__footer {
  display: flex;
  justify-content: space-between;
  color: #000;
  align-items: center;
  padding: 0 25px;
  z-index: 9999;
}

.button-wrapper {
  display: flex;
}

.modelSelector__button {
  display: inline-block;
  background: red;
  color: #fff;
  text-decoration: none !important;
  border-radius: 6px;
  -webkit-transform: skew(-30deg);
          transform: skew(-30deg);
  margin-right: 30px;
  cursor: pointer;
}

.modelSelector__cleanbutton {
  border: 1px solid #000;
  letter-spacing: 2px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  color: #000 !important;
  font-weight: 500;
  transition-duration: 0.6s;
  -webkit-user-select: none;
          user-select: none;
}

.modelSelector__cleanbutton a {
  padding: 10px 10px;
  display: inline-flex;
  text-decoration: none !important;
  min-width: 150px;
  text-align: center;
  color: #000 !important;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.modelSelector__cleanbutton button {
  background: none;
  border: none;
  outline: none;
  padding: 10px 30px;
  display: inline-block;
  text-decoration: none !important;
  min-width: 150px;
  text-align: center;
  color: #000 !important;
  cursor: pointer;
}

.modelSelector__cleanbutton.disabled {
  color: #ccc !important;
  border-color: #ccc;
}

.modelSelector__cleanbutton.disabled a {
  color: #ccc !important;
}

.modelSelector__cleanbutton:hover {
  background: #f0f0f0;
}

.button__clean {
  padding: 10px 70px;
  border: 1px solid #000;
  letter-spacing: 2px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  transition-duration: 0.6s;
  background: #fff;
}

.modelSelector__button.dark {
  background: #000;
}

.modelSelector__button a {
  color: #fff;
  text-decoration: none !important;
  display: inline-block;
  -webkit-transform: skew(30deg);
          transform: skew(30deg);
  padding: 10px 80px;
  font-weight: bold;
}

.modelSelector__rightLogo {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.option-additional-title {
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.option-additional .option-title {
  margin-top: 0;
  flex: 1 1;
}

.option-additional .option-content {
  border: 1px solid;
  background: #fff;
  margin: 10px;
  align-items: center;
  flex-direction: row;
  padding: 0;
  padding-right: 10px;
}

.option-additional .option-price {
  margin-top: 0;
}

.option-additional-text {
  width: 100%;
  border: 0;
  outline: none;
  padding: 10px;
}

.mobile-title {
  padding: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  font-size: 24px;
  box-shadow: 0 5px 5px 0px #00000033;
}

.model-name {
  display: none;
}

.mobile {
  display: none;
}

.option-picker {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.option-picker .option-image {
  width: 40px;
  height: 40px;
  padding: 3px;
  border-radius: 100%;
  cursor: pointer;
  transition-duration: 0.6s;
  border: 3px solid #ffffff;
}

.option-picker.option-selected .option-image {
  padding: 3px;
  border: 3px solid red;
}

.option-picker img {
  width: 40px;
}

.picker .options {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
}

.brand-logo {
  margin-left: 35px;
  padding-right: 10px;
}

.selectedModel__subtitle {
  flex: 1 1;
}

.m-only {
  display: none;
}

.modelSelector__right--close {
  position: absolute;
  top: 20px;
  right: 10px;
}

.mobile-title .pricing {
  display: none;
}

.buttons_mobile {
  display: none;
}

.login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-wrapper {
  width: 390px;
  border: 1px solid #ccc;
  box-shadow: 0 0 4px 0 #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.login-header {
  text-align: center;
  color: #fff;
  background: #1d2327;
  padding: 25px;
  font-weight: bold;
}
/* 
.login-content {
  padding: 35px;
} */

.login-form-item input {
  border: none;
  width: 100%;
  border-radius: 24px;
  background: #ebebeb;
  padding: 12px 25px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.login-form-item button {
  width: 100%;
  border: none;
  background: #1d2327;
  padding: 9px;
  border-radius: 32px;
  color: #fff;
  font-weight: 500;
  margin-top: 50px;
}

.login-form-item .login-form-error {
  margin-top: 35px;
  text-align: center;
  color: indianred;
}

.brokerage-section {
}

.brokerage-summary {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #000;
}

.brokerage-summary tr td {
  /* border-bottom: 1px solid #000; */
  height: 30px;
  border: 1px solid #999;
  padding: 0 10px;
}

.brokerage_input {
  font-family: 'Montserrat', sans-serif;
  border: none;
  text-align: right;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}

.brokerage_input[type='text'] {
  text-align: left;
}

.image-changer a {
  color: #383837;
  text-decoration: none !important;
  text-align: center;
  margin: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-sizing: border-box;
  padding: 9px;
}

.image-changer a.active {
  border: 2px solid #666;
}

.image-changer a img {
  width: 80px;
}

.image-changer-inner {
  display: flex;
  flex-direction: row;
  background-color: #ffffff99;
  padding: 2px;
  border-radius: 24px;
  border: 1px solid #666;
}

.sort {
  color: #000 !important;
  text-decoration: none !important;
}

.sort i {
  margin-left: 5px;
}

@media screen and (max-width: 992px) {
  .loading {
    margin-top: 100%;
  }

  .m-only {
    display: initial;
  }

  .modelSelector__title {
    font-size: 18px;
    margin-bottom: 0;
  }

  .modelSelector__footer {
    padding: 0;
  }

  .modelSelector__footer--right {
    margin-right: 5px;
  }

  .modelSelector__titleSlogan {
    font-size: 18px;
    margin-top: 0;
  }

  .modelSelector__title strong {
    font-size: 26px;
  }

  .modelSelector__item {
    flex-direction: column;
  }

  .step-image-content .image {
    display: none;
  }

  .brand-logo {
    margin: 0;
  }

  .brand-logo img {
    height: 20px !important;
  }

  .step-payment-content .left {
    padding: 2rem 1rem;
  }

  .selectedModel__subtitle--top {
    letter-spacing: initial;
  }

  .selectedModel__subtitle--bottom {
    letter-spacing: initial;
  }

  .dependency__dependencies {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .dependants__option--title {
    margin-left: 10px;
  }

  .spec_popup__close {
    right: 3% !important;
    top: 75px !important;
  }

  .spec_popup__container {
    flex-direction: column;
    z-index: 1000000;
    padding-top: 0 !important;
  }

  .upgradeoption-edit {
    display: none;
  }

  .spec_popup__image {
    flex: initial !important;
  }

  .spec_popup__modal {
    padding: 0 !important;
  }

  .spec_popup__content {
    padding: 0;
    margin-top: 15px;
  }

  .price-summary .payment-options,
  .price-summary .price-display {
    display: none;
  }

  .estimated {
    padding-right: 10px;
  }

  .option-additional-text {
    font-size: 9px;
  }

  .option-additional-title {
    font-size: 12px;
  }

  .title {
    padding: 1rem;
  }

  .option-group-subtitle {
    font-size: 18px;
    margin-top: 10px;
  }

  .step-summary-content .heading h1 {
    font-size: 22px;
  }

  .step-payment-content .left h2 {
    font-size: 22px;
  }

  .mobile-title {
    display: flex;
    align-items: center;
  }

  .mobile-title .pricing {
    flex: 1 1;
    font-size: 14px;
    text-align: right;
  }

  .form input,
  .form select {
    padding: 10px 0;
  }

  .form .form-item {
    padding: 6px;
  }

  .form label {
    margin-bottom: 5px;
    color: #444;
  }

  .step-payment-content .options-wrapper {
    display: none;
  }

  .button.wide {
    padding: 0.4rem 5rem;
  }

  .buttons_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .payment-options {
    font-size: 12px;
    display: inline-block;
    margin-right: 15px;
  }

  .price-display {
    display: inline-block;
  }

  .modelSelector__button.mobile {
    margin-top: 15px;
    margin-right: 0;
  }

  .footer .price-summary .price-text {
    font-size: 16px;
    display: inline-block;
  }

  .mobile {
    display: inline-block;
  }

  .title {
    display: flex;
  }

  .flex {
    display: block;
  }

  .app {
    display: block;
    min-height: 100vh;
    height: auto;
  }

  .content {
    display: block;
    padding-bottom: 100px;
  }

  .steps {
    display: none;
  }

  .header {
    justify-content: center;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-items: center;
  }

  .footer .price-summary {
    display: block;
    padding: 1rem;
  }

  .footer .payment-options {
    padding: 0;
  }

  .footer .delivery {
    display: none;
  }

  .footer .estimate-payment {
    display: none;
  }

  .step-content {
    display: block;
  }

  .button.wide {
    margin: 10px;
    display: block;
  }

  .step-content-inner.visible-mobile {
    display: block !important;
  }

  .step-payment-content .left {
    display: block;
  }

  .step-grid-content {
    display: block;
  }

  .option-group .group-optiongroup {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .step-summary-content,
  .step-payment-content {
    display: block;
  }

  .standards {
    display: block;
  }

  .upgrades {
    grid-template-columns: auto;
  }

  .form {
    grid-template-columns: auto;
  }

  .step-summary-content .options-wrapper,
  .step-payment-content .options-wrapper {
    width: auto;
  }

  .option-group.multi .option-group-options {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .option-group.multiImage .option-group-options {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .standards,
  .step-payment-content,
  .step-summary-content {
    padding: 0.5rem;
  }

  .step-complete-content {
    display: block;
  }

  .modelSelector__name {
    font-size: 18px;
    padding-bottom: 15px;
  }

  .modelSelector__items {
    grid-template-columns: auto auto;
  }

  .modelSelector__right {
    width: auto;
  }

  .selectedModel__specs {
    grid-template-columns: auto auto;
  }

  .selectedModel__tabs--item .tabs-icon {
    float: left;
    margin-left: 0;
    margin-right: 10px;
  }

  .selectedModel__tabs--title {
    font-size: 14px;
  }

  .selectedModel__tabs--content pre {
    font-size: 13px;
  }

  .option-image img {
    max-width: 100%;
  }

  .step-complete-content .complete-content {
    width: auto;
    padding: 25px;
  }

  .step-complete-content .complete-content .modelSelector__button {
    margin-top: 25px;
  }

  .step-complete-content .complete-content .modelSelector__button a {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 768px) {
  .modelSelector__items {
    grid-template-columns: auto;
  }
}

/* React tooltip */
.styles-module_tooltip__mnnfp {
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif;
}

.dependency {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000cc;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dependency__option--image {
  text-align: center;
  margin-bottom: 10px;
}

.dependency__option--image img {
  width: 100%;
}

.dependency__wrapper {
  background: #fff;
  padding: 25px;
  width: 800px;
  min-height: 60vh;
}

.dependency__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.dependecy__title {
  font-size: 22px;
}

.dependecy__close {
  cursor: pointer;
}

.dependency__content {
  padding-top: 15px;
  padding-bottom: 15px;
}

.dependency__dependencies {
  display: grid;
  justify-content: space-between;
  padding-top: 25px;
  grid-template-columns: repeat(3, 1fr);
}

.dependency__option {
  flex: 1 1;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dependency__dependant {
  border-bottom: 1px solid #ccc;
}

.dependency__dependant img {
  width: 100px;
}

.dependency__dependant .dependency__option {
  border: none;
  flex-direction: row;
  justify-content: center;
}

.dependency__info {
  text-align: center;
  margin-bottom: 10px;
}

.dependency__option--price {
  text-align: right;
}

